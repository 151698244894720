import styled from "@emotion/styled"

const Container = styled('div')(() => ({
    height: '1385px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

}))

export default Container 
