import styled from "@emotion/styled"

const ContainerContact = styled('div')(() => ({
    position: 'relative',
    height:'750px',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems:'center',
}))

export default ContainerContact